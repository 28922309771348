<template>
  <b-modal 
    ref="withdraw-confirmation-modal" 
    :title="title"
    :ok-title="okTitle"
    :cancel-title="cancelTitle"
    ok-variant="primary"
    cancel-variant="outline-secondary"
    size="sm"
    :hide-header-close="false"
    :centered="true"
    @close="onCloseClick"
    @cancel="onCancelClick"
    @ok="onOkClick"
  >
    <div>
      <p>{{ message }}</p>
    </div>
  </b-modal>
</template>

<script>
import { BFormInput } from "bootstrap-vue";
export default {
  name: "AomWithdrawApplicationModal",
  components: {
    BFormInput
  },
  data() { 
    return {
      title: 'Confirm Withdrawl',
      message: '',
      okTitle: 'OK',
      cancelTitle: 'Cancel',
      resolveReject: null,
      inputText: ''
    };
  },
  methods: {
    onInput(val) {
      this.okDisabled = val.trim().toUpperCase() !== 'DELETE';
    },
    confirmDelete(message, options) {
      this.message = message;
      this.title = options?.title || 'Confirm Delete';
      this.okTitle = 'Yes';
      this.cancelTitle = 'No';
      this.$refs['withdraw-confirmation-modal'].show();
      return new Promise((resolve, reject) => {
        this.resolveReject = { resolve, reject };
      });
    },
    onOkClick() {
      if (typeof this.resolveReject?.resolve === 'function') {
        this.resolveReject?.resolve(true);
      }
    },
    onCancelClick() {
      if (typeof this.resolveReject?.resolve === 'function') {
        this.resolveReject?.resolve(false);
      }
    },
    onCloseClick() {
      if (typeof this.resolveReject?.resolve === 'function') {
        this.resolveReject?.resolve(false);
      }
    }
  }
};
</script>
